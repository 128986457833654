import { ref, watch } from "vue";

export default function useCoordsReversed({ coordsRef, setCoords }) {
  const coordsReversed = ref(null);

  // Local state coords --> reversed
  watch(
    coordsRef,
    (newCoords) => {
      if (Array.isArray(newCoords) === false || newCoords.length !== 2) {
        return;
      }

      // Reverse coords
      const newCoordsReversed = newCoords.slice().reverse();

      // Return if already setted to prevent from infinite loop
      if (
        coordsReversed.value !== null &&
        coordsReversed.value.join() === newCoordsReversed.join()
      ) {
        return;
      }

      coordsReversed.value = newCoordsReversed;
    },
    { deep: true, immediate: true }
  );

  // Reversed --> Local state coords
  watch(
    coordsReversed,
    (newCoords) => {
      if (Array.isArray(newCoords) === false || newCoords.length !== 2) {
        return;
      }

      // Reverse coords
      const newCoordsReversed = newCoords.slice().reverse();

      // Return if already setted to prevent from infinite loop
      if (
        coordsRef.value !== null &&
        coordsRef.value.join() === newCoordsReversed.join()
      ) {
        return;
      }

      setCoords(newCoordsReversed);
    },
    { deep: true }
  );

  return {
    coordsReversed,
  };
}
