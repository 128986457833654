export default function useCommonDictionary() {
  const DICTIONARY_COMMON = {
    cityMarket: {
      wro: {
        slug: "wro",
        label: "Wrocław",
      },
    },
  };

  return { DICTIONARY_COMMON };
}
