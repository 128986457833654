import { googleMapsLoader } from "@/plugins/googleMaps";
import { onMounted, onUnmounted } from "vue";

export default function useGoogleMapsScript({ onScriptInit }) {
  const initGoogleMapsScript = async () => {
    console.log("INIT_GOOGLE_MAPS_SCRIPT");
    const google = await googleMapsLoader.load();

    onScriptInit(google);
    return new Promise((resolve) => resolve(google));
  };

  const deleteGoogleMapsScript = async () => {
    console.log("DELETE_GOOGLE_MAPS_SCRIPT");
    googleMapsLoader.deleteScript();
  };

  onMounted(() => {
    initGoogleMapsScript();
  });

  onUnmounted(() => {
    deleteGoogleMapsScript();
  });

  return {};
}
