<template>
  <div class="map-container">
    <div ref="mapHtmlRef" class="map" />
    <div class="map__fixed-marker-outer-circle" />
    <div class="map__fixed-marker-inner-circle" />
  </div>
</template>

<script>
import { ref, watch } from "vue";

import { debounce } from "lodash";
import useGoogleMapsMap from "@/composables/useGoogleMapsMap";

export default {
  props: {
    coordinatesLatLng: Array,
    zoom: Number,
  },

  setup(props, { emit }) {
    const mapHtmlRef = ref(null);
    const coordinatesReady = ref(props.coordinatesLatLng);

    const onMapChange = (newCoordinatesLatLng) => {
      coordinatesReady.value = newCoordinatesLatLng;
    };
    const onMapChangeDebounced = debounce(onMapChange, 300);

    const { updateMap } = useGoogleMapsMap({
      mapHtmlRef,
      onMapChange: onMapChangeDebounced,
      mapOptions: {
        zoom: props.zoom,
        centerCoordinates: props.coordinatesLatLng,
      },
    });

    watch(coordinatesReady, (newCoordinates) =>
      emit("update:coordinatesLatLng", newCoordinates)
    );

    watch(
      () => props.coordinatesLatLng,
      (newCoordinates) => {
        if (Array.isArray(newCoordinates) && newCoordinates.length !== 2) {
          return;
        }

        coordinatesReady.value = newCoordinates;

        try {
          updateMap(coordinatesReady.value);
        } catch (error) {
          console.warn(`Tried to update map, but got error: ${error}`);
        }
      }
    );

    return { mapHtmlRef, coordinatesReady };
  },
};
</script>

<style scoped>
.map-container {
  width: 560px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.map {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.map__fixed-marker-outer-circle {
  position: absolute;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #903bfc;
  background: linear-gradient(92deg, #d943db 3.72%, #33429f 95.9%);
  opacity: 0.2;
  background-blend-mode: darken;
  box-shadow: 0px 5px 25px 0px #f2f2f2;
  border-radius: 50%;
  pointer-events: none;
  cursor: grab;
}
.map__fixed-marker-inner-circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #562693;
  border: 2px solid #fff;
  pointer-events: none;
  cursor: grab;
}

@media (max-width: 1200px) {
  .map-container {
    width: 100%;
    height: 250px;
  }
}
</style>
