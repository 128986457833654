import useGoogleMapsScript from "./useGoogleMapsScript";

export default function useGoogleMapsAutocomplete() {
  let autocompleteServiceInstance = null;
  let googleMaps = null;

  const onScriptInit = (google) => {
    googleMaps = google.maps;
    autocompleteServiceInstance = new googleMaps.places.AutocompleteService();
  };

  useGoogleMapsScript({ onScriptInit });

  const getPredictions = async ({
    input,
    location, // Type:  LatLng --> Location for prediction biasing.
    radius = 50000, // Type:  number --> The radius of the area used for prediction biasing. (in meters)
  }) => {
    if (googleMaps === null) {
      throw new Error("GoogleMapsScript was not activated yet!");
    }

    if (!location) {
      //   Todo: refactor to dynamic defaults!
      // Set default to center of Current user city (Wroclaw for now)
      location = new googleMaps.LatLng({
        lat: 51.1078852,
        lng: 17.0385376,
      });
    } else if (Array.isArray(location)) {
      location = new googleMaps.LatLng(...location);
    } else if (
      typeof location === "object" &&
      Array.isArray(location) === false
    ) {
      location = new googleMaps.LatLng(location);
    }

    const requestOptions = {
      input: input,
      location: location,
      radius: radius,
      componentRestrictions: {
        // Todo: set country from user store !
        country: "pl",
      },
      types: ["geocode"],
    };

    const { predictions } =
      await autocompleteServiceInstance.getPlacePredictions(requestOptions);

    return predictions;
  };

  return {
    getPredictions,
  };
}
