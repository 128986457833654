<template>
  <InputTextGeo
    v-model:address="addressInput"
    :suggestions="suggestionsRef"
    @set-suggestion="setSuggestion($event)"
    @auto-geolocation="autoGeolocate()"
    @focus="isFocusedAddressInput = true"
    @blur="isFocusedAddressInput = false"
  />
</template>

<script>
import { ref, watch } from "vue";

import { debounce } from "lodash";
import InputTextGeo from "../UI/InputTextGeo.vue";
import useGoogleMapsAutocomplete from "@/composables/useGoogleMapsAutocomplete";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";

export default {
  components: { InputTextGeo },

  props: {
    address: String,
    coordinatesLatLng: Array,
  },

  emits: ["update:coordinatesLatLng", "update:address"],

  setup(props, { emit }) {
    const coordinatesReady = ref(null);
    const addressInput = ref(props.address);
    const suggestionsRef = ref([]);

    // Two-way binding for current coordinates
    watch(coordinatesReady, (newCoordinates) => {
      emit("update:coordinatesLatLng", newCoordinates);
    });
    watch(
      () => props.coordinatesLatLng,
      (newCoordinates) => {
        coordinatesReady.value = newCoordinates;
      }
    );

    // Incoming binding for current address
    watch(
      () => props.address,
      (newAddress) => {
        addressInput.value = newAddress;
      }
    );

    const isFocusedAddressInput = ref(false);
    // Get new suggestions on address input
    watch(addressInput, (newAddressInput) => {
      if (!isFocusedAddressInput.value) {
        return;
      }

      if (newAddressInput.length < 5) {
        return;
      }

      getSuggestionsDebounced(
        {
          input: newAddressInput,
          location: coordinatesReady.value,
          radius: 50000,
        },
        (suggestions) => {
          suggestionsRef.value = suggestions;
        }
      );
    });

    const { getPredictions } = useGoogleMapsAutocomplete();

    const getSuggestions = async (options) => {
      const predictions = await getPredictions(options);

      const suggestions = predictions.map((prediction) => ({
        label: prediction.description,
        address: prediction.description,
        placeId: prediction.place_id,
      }));

      return suggestions;
    };

    const getSuggestionsDebounced = debounce(
      (options, resolve) =>
        getSuggestions(options).then((suggestions) => resolve(suggestions)),
      500
    );

    const { geocodeToCoordsLatLng } = useGoogleMapsGeocoder();

    const setSuggestion = async (suggestion) => {
      emit("update:address", suggestion.label);

      const request = suggestion.placeId
        ? {
            placeId: suggestion.placeId,
          }
        : {
            address: suggestion.address,
          };

      const coordsLatLng = await geocodeToCoordsLatLng(request);

      if (coordsLatLng === null) {
        alert(
          "Something went wrong... :( We cannot find you on map, please do it manually."
        );
      }

      coordinatesReady.value = coordsLatLng;
    };

    const autoGeolocate = () => {
      if (!navigator.geolocation) {
        // Browser doesn't support HTML5 Geolocation
        alert("Your browser doesn't support Geolocation :(");
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          coordinatesReady.value = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
        () => {
          alert("Can't get your location :(");
        }
      );
    };

    return {
      coordinatesReady,
      addressInput,
      suggestionsRef,
      setSuggestion,
      autoGeolocate,
      isFocusedAddressInput,
    };
  },
};
</script>

<style scoped>
.map {
  display: block;
  width: 560px;
  height: 250px;

  border-radius: 20px;
}

@media (max-width: 1200px) {
  .map {
    display: block;
    width: 100%;
    height: 250px;
  }
}
</style>
