import { Loader } from "@googlemaps/js-api-loader";

export const googleMapsLoader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"],
  region: "PL",
  language: "pl", // TODO: should depend on website region
  retries: 5,
});
