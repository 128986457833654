import { ref } from "vue";
import useGoogleMapsScript from "./useGoogleMapsScript";

export default function useGoogleMapsGeocoder() {
  let geocoderInstance = null;
  let googleMaps = null;
  const isInited = ref(false);

  const onScriptInit = (google) => {
    googleMaps = google.maps;
    geocoderInstance = new googleMaps.Geocoder();
    isInited.value = true;
  };

  useGoogleMapsScript({ onScriptInit });

  const geocode = async (request) => {
    return new Promise((resolve) => {
      if (geocoderInstance === null) {
        throw new Error("GoogleMaps Geocoder was not activated yet!");
      }
      geocoderInstance.geocode(request, (results, status) => {
        if (status !== "OK") {
          resolve(null);
        }

        resolve(results);
      });
    });
  };

  const geocodeToCoordsLatLng = async (request) => {
    const results = await geocode(request);

    if (results === null) {
      return null;
    }

    const resultLocation = results[0].geometry.location.toJSON();
    const resultCoordsLatLng = Object.values(resultLocation);

    return resultCoordsLatLng;
  };

  const zipToCoordsLatLng = async (zip) => {
    const zipCoordsLatLng = await geocodeToCoordsLatLng({ address: zip });

    return zipCoordsLatLng;
  };

  const coordsLatLngToCityName = async (coordsLatLng) => {
    console.log("GEOCODER_COORDS_TO_CITY_NAME");

    if (geocoderInstance === null) {
      throw new Error("GoogleMaps Geocoder was not activated yet!");
    }

    if (googleMaps === null) {
      throw new Error("GoogleMapsScript was not activated yet!");
    }

    const location = new googleMaps.LatLng(...coordsLatLng);

    return new Promise((resolve, reject) => {
      geocoderInstance.geocode({ location: location }, (results, status) => {
        if (status !== "OK") {
          return reject(`Geocoder status !== OK, received ${status}.`);
        }

        const localityResult = results.find((result) => {
          if (!result.types) {
            return;
          }

          return result.types.includes("locality");
        });

        if (!localityResult) {
          return reject(`Geocoder: No result with locality.`);
        }

        const locality = localityResult.address_components.find(
          (addressComponent) => {
            if (!addressComponent.types) {
              return;
            }

            return addressComponent.types.includes("locality");
          }
        );

        if (!locality) {
          return reject(`Geocoder: No address_components with locality.`);
        }

        const city = locality.long_name || locality.short_name;

        if (!city) {
          return reject(`Geocoder: No locality long or short name found.`);
        }

        resolve(city);
      });
    });
  };

  return {
    isInited,
    coordsLatLngToCityName,
    zipToCoordsLatLng,
    geocode,
    geocodeToCoordsLatLng,
  };
}
