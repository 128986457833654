import { removeListeners } from "@/helpers/utils";
import { onUnmounted } from "vue";
import useGoogleMapsScript from "./useGoogleMapsScript";

export default function useGoogleMapsMap({
  mapHtmlRef,
  onMapInit,
  onMapChange,
  mapOptions,
}) {
  const listeners = [];
  let mapInstance = null;
  let googleMaps = null;

  const initMap = ({ mapHtmlRef, onChange, mapOptions }) => {
    console.log("INIT_GOOGLE_MAPS_MAP");

    if (!mapHtmlRef) {
      throw new Error("No mapHtmlRef is provided!");
    }

    if (!mapOptions) {
      throw new Error("No map options provided!");
    }

    const { zoom = 7, centerCoordinates } = mapOptions;

    let centerLatLng;

    if (!centerCoordinates) {
      //   Todo: refactor to dynamic defaults!
      // Set default to center of Wroclaw
      centerLatLng = new googleMaps.LatLng({
        lat: 51.1078852,
        lng: 17.0385376,
      });
    } else if (
      Array.isArray(centerCoordinates) &&
      centerCoordinates.length === 2 &&
      typeof centerCoordinates[0] === "number" &&
      typeof centerCoordinates[1] === "number"
    ) {
      centerLatLng = new googleMaps.LatLng(...centerCoordinates);
    } else if (
      typeof centerCoordinates === "object" &&
      Array.isArray(centerCoordinates) === false
    ) {
      centerLatLng = new googleMaps.LatLng(centerCoordinates);
    }

    mapInstance = new googleMaps.Map(mapHtmlRef.value, {
      center: centerLatLng,
      zoom: zoom,
      disableDefaultUI: true,
    });

    if (onChange) {
      const centerChangedListener = mapInstance.addListener(
        "center_changed",
        () => {
          const newCenterCoordinates = Object.values(
            mapInstance.getCenter().toJSON()
          );
          onChange(newCenterCoordinates);
        }
      );

      listeners.push(centerChangedListener);
    }

    return new Promise((resolve) => resolve(mapInstance));
  };

  const onScriptInit = (google) => {
    googleMaps = google.maps;
    initMap({ mapHtmlRef, onChange: onMapChange, mapOptions }).then(
      (mapInst) => {
        if (onMapInit) {
          onMapInit(mapInst);
        }
      }
    );
  };

  useGoogleMapsScript({ onScriptInit });

  const updateMap = (coordinatesLatLng) => {
    if (googleMaps === null) {
      throw new Error("GoogleMapsScript was not actvivated yet!");
    }

    if (mapInstance === null) {
      throw new Error("GoogleMaps mapInstance was not actvivated yet!");
    }

    let centerLatLng;

    if (Array.isArray(coordinatesLatLng)) {
      centerLatLng = new googleMaps.LatLng(...coordinatesLatLng);
    }

    if (
      coordinatesLatLng != null &&
      Array.isArray(coordinatesLatLng) === false
    ) {
      centerLatLng = new googleMaps.LatLng(coordinatesLatLng);
    }

    mapInstance.panTo(centerLatLng);
  };

  onUnmounted(() => {
    removeListeners(listeners);
  });

  return {
    updateMap,
  };
}
